input[type=text],select, input[type=password] {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
   
  }
  
  /* Add a background color when the inputs get focus */
  input[type=text]:focus, input[type=password]:focus {
    background-color: #ddd;
    outline: none;
  }
 
  /* Set a style for all buttons */
  /* button {
    background-color: #f15943;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
  } */
  
  button:hover {
    opacity:1;
  }
  
  /* Float cancel and signup buttons and add an equal width */
   .signupbtn {
    float: left;
    width: 100%;
  }
  
  /* Add padding to container elements */
 
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #0F6D65;
    border: 1px solid #888;
    width: 75%;
    height: auto;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    margin: auto;
}
  /* Style the horizontal ruler */
  hr {
    border: 1px solid #f1f1f1;
    margin-bottom: 25px;
  }
   
  /* The Close Button (x) */
  .close {
    position: absolute;
    right: 35px;
    top: 15px;
    font-size: 40px;
    font-weight: bold;
    color: #f1f1f1;
  }
  
  .close:hover,
  .close:focus {
    color: #f44336;
    cursor: pointer;
  }
  
  /* Clear floats */
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
  
  /* Change styles for cancel button and signup button on extra small screens */
  @media screen and (max-width: 300px) {
     .signupbtn {
       width: 100%;
    }
  }


  /* .signup_form {
    background-image: url("/images/bg1.jpg");
    padding: 50px;
} */
.heading_center {
  text-align: center;
  color: #fff;
  padding-top: 15px;
}
.text-lg-right.top-right-bar.mt-2.mt-lg-0 a {
  margin: 5px;
}

.S_button {
  background: #F15943;
  display: inline-flex;
  color: #fff;
  margin: 17px;
  padding: 20px;
}

.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
  position: relative;
}

img.avatar {
  width: 20%;
  border-radius: 50%;
}
.section_divided {
  padding: 10px 0;
  text-align: center;
  margin: 20px 0;
}
form.modal-content label {
  color: #fff;
}

/* Doctor Register Page  */
.Section_full {
  width: 100%;
  float: left;
  background-color: #fff;
}
.sidebar {
  margin: 0;
  padding: 0;
  width: 15%;
  background-color: #0f6d65;
  height: 545px;
  overflow: auto;
  /* box-shadow: 10px 0px 1em 0em #0F6D65; */
  float: left;
  max-height: 100%;
}
.sidebar a {
  display: block;
  color: white;
  padding: 16px;
  text-decoration: none;
}
 
.sidebar a.active {
  background-color: #f15943;
  color: white;
}

.sidebar a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.content_bar {
  padding: 35px;
  /* box-shadow: 0px 0px 1em 0.5em #0F6D65; */
  box-sizing: content-box;
  width: 80%;
  float: right;
  margin: 50px 0;
  border: 2px solid #0F6D65;
  border-radius: 50px;
}
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .main_div {
    width: 100% !important; 
    float: left;
}
.section_column { 
  display: block !important;
}
  .sidebar a {float: left;}
  div.content {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}
.Text_div {
  text-align: center;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
  margin-top: 25px;
}



.table_design table {
  width: 100%;
  float: left;
  text-align: center;
}
.table_design thead tr, th {
  background-color: #0f6d65;
  color: #fff;
  padding: 20px;
  text-transform: capitalize;
}
.table_username {
  text-align: center;
  font-size: 20px;
  background-color: #fff;
  color: #f15943;
  border: none;
  padding: 5px;
  width: 100%;
  font-weight: bold;
}
.content_bar h2 {
  text-align: center;
}
.btn-danger {
  padding: 10px;
  text-transform: capitalize;
}
.btn-success {
  text-transform: capitalize;
  padding: 10px;
}
#service, #gender {
  width: 100%;
  padding: 10px;
}
.section_row{
  float: left;
  width: 100%;
  margin: 50px 0;
  text-align: center;
}
.main_div {
  width: 85%;
  float: left;
}
.section_column {
  float: left;
  padding: 40px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  border: 1px solid;
  margin: 5px 5px 5px 5px;
  border-radius: 30px;
  height: auto;
  display: inline-flex;
  width: 99%;
}
.role_get {
  padding: inherit;
  position: relative;
  width: inherit;
}


* {
  box-sizing: border-box;
}

form.example input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: left;
  width: 80%;
  background: #f1f1f1;
}

form.example button {
  float: left;
  width: 20%;
  padding: 10px;
  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}

.fotter {
  padding-top: 50px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.second_section {
  width: 50%;
  float: left;
  background: #f15943;
  color: #fff;
  position: relative;
  height: 455px;
  padding: 50px;
  border-radius: 20px;
  text-align: center;
}
/* .second_section h2, p {
  transform: translateX(0%)translateY(24%);
} */
.button_parent {
  width: 100%;
  float: left;
  border-radius: 20px;
  padding: 15px;
  background: #f15943;
  color: #fff;
}
.button_login {
  width: 50%;
  float: left;
  padding: 30px;
  color: #fff;
}
.sigin_box {
  width: 50%;
  color: #0f6d65;
  background: #fff;
  padding: 12px;
  border-radius: 15px;
}
.login_para p {
  width: 100%;
  float: left;
}